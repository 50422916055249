<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Pop Feed</strong>
            </div>
            <div class="text-muted">Create new Pop Feed</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Publisher of the Pop Feed.</span>
                      </template>
                      <a-select @change="domainHandler"
                        v-decorator="['PublisherId', { initialValue: initialValue, rules: [{ required: true, message: 'Please select publisher' }] }]"
                        placeholder="Please select a publisher" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="pub in ORTBpublisher.list.filter(ele => ele.Status === 0)"
                          :value="pub.Id" :key="pub.Id">[{{
              pub.Id }}] {{ pub.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Pop Feed's name.</span>
                      </template>
                      <a-input type="text" placeholder="Pop Feed Name" v-decorator="['name', {
              rules: [
                { required: true, message: 'Name is required.' },
                { max: 64, message: 'Maximum 64 characters allowed.' }
              ]
            }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that Pop Feed's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%"
                        v-decorator="['publisherRevShare', { initialValue: 100 }]"
                        placeholder="Publisher Revenue Share">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Minimum bid expressed in CPM. Value will be used as a bidfloor in RTB auctions.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Bidfloor"
                                v-decorator="[ 'bidfloor', { initialValue: 0.1, rules: [{validator: cpmValidator}] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of RTB auction within the platform.</span>
                      </template>
                      <a-select v-decorator="['auctionType', { initialValue: '2' }]" placeholder="Auction Type"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option value='1'>First Price</a-select-option>
                        <a-select-option value='2'>Second Price Plus</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax" v-decorator="['tmax', { initialValue: 350 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Secure">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the Pop Feed requires secure HTTPS URL creative assets and markup</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="secureValue" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                      </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                      </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                      v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Pop Feed. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                      v-decorator="['SpentDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                      <a-tooltip slot="addonBefore" trigger="click">
                        <a-icon type="dollar" />
                      </a-tooltip>
                    </a-input>
                  </a-tooltip>
                </a-form-item>
                </div>
              </div>
              <a-divider class="mt-3">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m ">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher sub IDs.</span>
                      </template>
                      <a-select
                        v-decorator="['SubidListMode', { initialValue: 'Blacklist'}]"
                        placeholder="Subid List Mode"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option key="0">Blacklist</a-select-option>
                        <a-select-option key="1">Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Sub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of sub IDs to block or allow.</span>
                      </template>
                      <a-select
                        v-decorator="['SubidList']"
                        placeholder="Subid List"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                        mode="tags"
                        :tokenSeparators="[' ']"
                      >
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of Keywords blacklists filtering.</span>
                      </template>
                      <a-select
                        v-decorator="['KeywordBlacklistsType', {initialValue: 'Exact'}]"
                        placeholder="Keyword Blacklists Type"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option v-for="keywordListType in keywordListsType" :key="keywordListType.Id">
                          {{ keywordListType.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keyword Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Keyword lists to set to the search feed as blacklists.</span>
                      </template>
                      <a-select
                        v-decorator="['KeywordBlacklists', {initialValue: []}]"
                        placeholder="Keyword Blacklists"
                        :showSearch="true"
                        :filterOption="true"
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        <a-select-option v-for="keywordList in activeKwlist" :key="keywordList.Id">{{ keywordList.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Pop Feed</button>
                  <button type="button" @click="$router.push({ name: 'popfeeds' })"
                  class="btn btn-light px-5 ml-2">Cancel
                </button>
              </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
// import Vue from 'vue'

export default {
  components: {},
  computed: {
    ...mapState(['ORTBpublisher', 'rtbzone', 'popfeed', 'kwlist']),
    domainsBrandsListString() {
      return this.domainsBrandsList.join('\n')
    },
    appsListString() {
      return this.appsList.join('\n')
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    activeKwlist() {
      return this.$store.getters['kwlist/getActiveKwLists']
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    pubIdListString() {
      return this.pubIdList.join('\n')
    },
    cridListString() {
      return this.cridList.join('\n')
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    adDomainsBrandsBlacklistString() {
      return this.adDomainsBrandsBlacklist.join('\n')
    },
    adAppsBlacklistString() {
      return this.adAppsBlacklist.join('\n')
    },
    showSizesOptimization() {
      // return this.rtbzoneData.OptimizationSizes !== [] ? this.rtbzoneData.OptimizationSizes : []
      return []
    },
    sizesTotalRows() {
      return this.showSizesOptimization.length
    },
    filteredSizesFields() {
      return this.sizesFields.filter(c => c.show)
    },
    initialValue() {
      if (this.$route.params.publisherId) {
        const publisher = this.ORTBpublisher.list.find(ele => ele.Id === parseInt(this.$route.params.publisherId))
        return publisher === undefined ? [] : publisher.Id
      } else {
        return []
      }
    },
  },

  data() {
    return {
      sizes: zoneSize,
      jsonDomain: '',
      ptDomain: '',
      filterSearch: '',
      filter: '',
      sortDirection: 'asc',
      sortBy: 'Bids',
      sortDesc: true,
      sizesListMode: undefined,
      bannerEnabled: true,
      videoEnabled: true,
      audioEnabled: true,
      nativeEnabled: true,
      popEnabled: true,
      webEnabled: true,
      appEnabled: true,
      nonEmptyRefererEnabled: true,
      secureValue: true,
      popAdmXml: false,
      ipMatchEnabled: true,
      uaMatchEnabled: true,
      sizesCurrentPage: 1,
      sizesPerPage: 50,
      unformattedResponse: [],
      treeData: [],
      selectedKeys: [],
      tst: [],
      autoExpandParent: false,
      checkedKeys: [],
      adDomainsBrandsBlacklist: [],
      adAppsBlacklist: [],
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      supplyLabelCol: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      supplyWrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      keywordLists: [],
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
    }
  },

  methods: {
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    domainHandler(val) {
      const publisher = this.ORTBpublisher.list.find(pub => pub.Id === val)
      const publisherPlatformDomain = publisher.PlatformDomain
      const publisherJsonDomain = publisher.SellersJsonDomain
      this.jsonDomain = publisherJsonDomain
      this.ptDomain = publisherPlatformDomain
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.Secure = this.secureValue ? 1 : 0
          this.$store.dispatch('popfeed/CREATE_FEED', {
            payload: {
              ...values,
            },
          }).then(response => {
            const id = response.Popfeed.Id
            this.$router.push({ name: 'popfeed-update', params: { id } })
          }).catch(error => {
            console.error('Error creating Pop Feed:', error)
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
